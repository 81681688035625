import Rails from "@rails/ujs";

document.addEventListener("DOMContentLoaded", () => {
  Rails.start();

  const form = document.querySelector("form");

  if (form) {
    /**
     * Loading spinner for form submissions on the submit button
     */
    form.addEventListener("submit", () => {
      const submit = form.querySelector('[type="submit"]');
      const submitLoader = form.querySelector(".submit--loading");
      const submitContent = form.querySelector(".submit--content");

      submit?.classList.remove("hover:bg-emerald-600", "hover:shadow");
      submitContent?.classList.add("invisible");
      submitLoader?.classList.remove("invisible");

      window.requestAnimationFrame(() => {
        Array.from(form.querySelectorAll("input")).forEach((input) => {
          input.disabled = true;
          input.classList.remove("hover:shadow");
        });
      });
    });
  }
});
